<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-6">
              <label for="name">{{ $t('agents_customers.agent_name') }}</label>
              <multiselect
                  v-model="agent"
                  :placeholder="$t('agents_customers.agent_name')"
                  label="name"
                  track-by="id"
                  :options="agents"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  :internal-search="true"
              >
              </multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('agents_customers.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('agents_customers.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  <span><i class="fas fa-search"></i>
                      <span>{{ $t('search') }}</span>
                  </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                    <span><i class="fas fa-trash-restore"></i>
                        <span>{{ $t('reset_search') }}</span>
                    </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <custom-export-data :data-list="dataList" :route="mainRoute" :filters="filters" :fields="json_fields" :file-name="$t('MENU.agents_and_customers')"></custom-export-data>
        </div>

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

        </v-server-table>

      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-agents-and-customers",
  components: {},
  data() {
    return {
      mainRoute: 'agents/agent_customers',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        agent_id: null,
        from_date: null,
        to_date: null,
        from_rate: null,
        to_rate: null,
      },
      columns: ['agent_name', 'client_name', 'start_date', 'end_date', 'no_of_payment','total_payment', 'status'],
      data: [],

      agent: null,
      agents: [],
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('agents_customers.agent_name')] = 'agent_name';
      fields[this.$t('agents_customers.client_name')] = 'client_name';
      fields[this.$t('agents_customers.start_date')] = 'start_date';
      fields[this.$t('agents_customers.end_date')] = 'end_date';
      fields[this.$t('agents_customers.no_of_payment')] = 'no_of_payment';
      fields[this.$t('agents_customers.total_payment')] = 'total_payment';
      fields[this.$t('status')] = 'status';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          agent_name: that.$t('agents_customers.agent_name'),
          client_name: that.$t('agents_customers.client_name'),
          start_date: that.$t('agents_customers.start_date'),
          end_date: that.$t('agents_customers.end_date'),
          no_of_payment: that.$t('agents_customers.no_of_payment'),
          total_payment: that.$t('agents_customers.total_payment'),
          status: that.$t('status'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    "agent": function (val) {
      if (val && val.id) {
        this.filters.agent_id = val.id;
      }else {
        this.filters.agent_id = null;
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.agents_and_customers")}]);
    this.getAgents();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.agent_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.from_rate = null;
      this.filters.to_rate = null;
      this.agent = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },

    getAgents() {
        ApiService.get(this.mainRouteDependency+"/agents").then((response) => {
          this.agents = response.data.data;
        });
    },

  },
};
</script>
